import React, { createContext, ReactElement, useCallback, useContext, useRef, useState } from 'react';
import { CToaster } from '@coreui/react';
import { Toast, ToastProps } from '../components';

interface IToastContext {
  addToast: (message: string, options?: ToastProps) => void;
}

const ToastContext = createContext<IToastContext | undefined>(undefined);
ToastContext.displayName = `ToastContext`;

export const ToastProvider: React.FC = ({ children }) => {
  const toaster = useRef();
  const [ toast, pushToast ] = useState<ReactElement>();

  const addToast = useCallback((message: string, options?: ToastProps) => {
    pushToast(<Toast {...options}>{message}</Toast>);
  }, [ pushToast ]);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {/* @ts-expect-error what the heck */}
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </ToastContext.Provider>
  );
};

export const useToasts = (): IToastContext => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }
  return context;
};
