import React from 'react';
import { CFormInput } from '@coreui/react';
import { Column } from '@tanstack/react-table';

export const TextEntryColumnFilter: React.FC<{ column: Column<any, unknown> }> = ({ column }) => {
  const filterValue = column.getFilterValue();

  return <CFormInput
    data-testid={`${column.id}-filter`}
    placeholder="Search"
    value={filterValue === undefined ? `` : filterValue as string}
    onChange={(e) => {
      column.setFilterValue(e.target.value === `` ? undefined : e.target.value);
    }}
    onClick={(e) => e.stopPropagation()}
  />;
};
