import React from 'react';
import { CToast, CToastBody, CToastClose, CToastHeader } from '@coreui/react';
import cn from 'classnames';

type AppearanceTypes = 'error' | 'info' | 'success' | 'warning';
export interface ToastProps {
  appearance?: AppearanceTypes;
  autoDismiss?: boolean | number;
  autoDismissTimeout?: number;
}

export const Toast: React.FC<ToastProps> = ({
  appearance = `info`,
  autoDismiss = true,
  autoDismissTimeout = 3000,
  children,
}) => <CToast
  autohide={!!autoDismiss}
  delay={autoDismissTimeout}
  visible
  animation
>
  <CToastHeader
    className={cn(`font-weight-bold`, {
      'bg-danger': appearance === `error`,
      'bg-info': appearance === `info`,
      'bg-success': appearance === `success`,
      'text-white': appearance === `success` || appearance === `error` || appearance === `info`,
    })}
  >
    {appearance.charAt(0).toUpperCase() + appearance.substr(1).toLowerCase()}
    <CToastClose className="me-2 m-auto" />
  </CToastHeader>
  <CToastBody>{children}</CToastBody>
</CToast>;
