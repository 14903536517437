import React, { BaseSyntheticEvent, useMemo } from 'react';
import { CFormSelect } from '@coreui/react';
import { Column } from '@tanstack/react-table';

export const SelectColumnFilter: React.FC<{ column: Column<any, unknown> }> = ({ column }) => {
  const options = useMemo(() =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    Array.from(column.getFacetedUniqueValues().keys()).sort(),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ column.getFacetedUniqueValues() ]);

  const filterValue = column.getFilterValue();

  return <CFormSelect
    data-testid={`${column.id}-filter`}
    value={filterValue === undefined ? `` : filterValue as string}
    onChange={(e: BaseSyntheticEvent) => {
      column.setFilterValue(e.target.value === `` ? undefined : e.target.value);
    }}
    onClick={(e) => e.stopPropagation()}
  >
    <option value={``}>All</option>
    {options.map((option, i) =>
      <option key={i} value={option}>
        {option}
      </option>)}
  </CFormSelect>;
};
