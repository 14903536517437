import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { AbilityProvider } from './AbilityContext';
import { SidebarProvider } from './SidebarContext';
import { ConfirmationProvider } from './ConfirmationContext';
import { ToastProvider } from './ToastContext';

export const AppProviders: React.FC = ({ children }) =>
  <React.StrictMode>
    <ToastProvider>
      <Router>
        <AuthProvider>
          <AbilityProvider>
            <ConfirmationProvider>
              <SidebarProvider>
                {children}
              </SidebarProvider>
            </ConfirmationProvider>
          </AbilityProvider>
        </AuthProvider>
      </Router>
    </ToastProvider>
  </React.StrictMode>;
