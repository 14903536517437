import {
  BridgeDTO,
  IAudited,
  SearchBridgesDTO,
} from 'smart-bridges';
import { AxiosResponse } from 'axios';
import Axios from '../utils/http.config';

const BASE_URL = `/bridge`;

export class BridgeService {
  public static async getBridgeByID(id: number): Promise<IAudited<BridgeDTO>> {
    return await Axios.get<{ bridge: IAudited<BridgeDTO> }>(`${BASE_URL}/${id}`)
      .then(response => response.data.bridge);
  }

  public static async getBridgeImageByID(id: number): Promise<string> {
    return await Axios.get(`${BASE_URL}/image/${id}`, { responseType: `blob` })
      .then((response: AxiosResponse<Blob>) => {

        const url = URL.createObjectURL(response.data);
        return url;
      });
  }

  public static async create(bridge: BridgeDTO): Promise<AxiosResponse> {
    return await Axios.post(`${BASE_URL}`, bridge);
  }

  public static async search(params: SearchBridgesDTO = {}): Promise<Array<IAudited<BridgeDTO>>> {
    return await Axios.get<{ bridges: Array<IAudited<BridgeDTO>> }>(`${BASE_URL}`, {
      params: {
        relations: [ `creator` ],
        ...params,
      },
    })
      .then(response => response.data.bridges);
  }

  public static bulkCreate(data: FormData): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/bulk`,
      data,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
  }
}
