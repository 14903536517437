import React, { createContext, useContext, useState } from 'react';
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';

interface ConfirmProps {
  title: string;
  message?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  stackedModal?: boolean;
  onConfirm: () => void;
}

interface IConfirmationContext { createConfirmation: (confirmation: ConfirmProps) => void }

const ConfirmationContext = createContext<IConfirmationContext | null>(null);

export const useConfirmation = () => {
  const ctx = useContext(ConfirmationContext);
  if (!ctx) { throw new Error(`useConfirmation must be used within a ConfirmationProvider`); }
  return ctx;
};

export const ConfirmationProvider: React.FC = ({ children }) => {
  const [ show, setShow ] = useState(false);
  const [ confirmation, setConfirmation ] = useState<ConfirmProps>();

  const createConfirmation = (props: ConfirmProps) => {
    setConfirmation(props);
    setShow(true);
  };

  return <ConfirmationContext.Provider value={{ createConfirmation }}>
    {children}
    {confirmation &&
      <CModal
        visible={show}
        onClose={() => setShow(false)}
        className={confirmation.stackedModal ? `stacked-modal` : ``}
      >
        <CModalHeader closeButton>
          <CModalTitle>{confirmation.title}</CModalTitle>
        </CModalHeader>
        <CModalBody className="text-dark" data-testid="confirmationModal">
          {confirmation.message || `Are you sure?`}
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            data-testid="cancelButton"
            className="px-4 font-weight-bold float-end"
            onClick={() => setShow(false)}
          >
            {confirmation.cancelText || `Cancel`}
          </CButton>
          <CButton
            color="danger"
            data-testid="confirmButton"
            className="px-4 font-weight-bold float-end"
            onClick={() => {
              confirmation.onConfirm();
              setShow(false);
            }}
          >
            {confirmation.confirmText || `Confirm`}
          </CButton>
        </CModalFooter>
      </CModal>}
  </ConfirmationContext.Provider>;
};
