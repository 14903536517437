import React from 'react';
import { useAuth } from 'contexts/AuthContext';
import './scss/style.scss';
import 'leaflet/dist/leaflet.css';

const loading =
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>;

const AuthenticatedApp = React.lazy(() => import(`./views/entry/AuthenticatedApp`));
const UnauthenticatedApp = React.lazy(() => import(`./views/entry/UnauthenticatedApp`));

const App: React.FC = () => {
  const { user } = useAuth();

  return <React.Suspense fallback={loading}>
    {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
  </React.Suspense>;
};

export default App;
