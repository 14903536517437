import { AxiosResponse } from 'axios';
import {
  AuthTokenDTO,
  LoginDTO,
  PasswordResetDTO,
  PasswordResetRequestDTO,
  PasswordResetTokenDTO,
} from 'smart-bridges';
import Axios from '../utils/http.config';

const BASE_URL = `/auth`;

export class AuthService {
  public static login(data: LoginDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/login`, data);
  }

  public static logout(): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/logout`, {});
  }

  public static whoAmI(): Promise<AuthTokenDTO> {
    return Axios.get<{ user: AuthTokenDTO }>(`${BASE_URL}/whoami`, {})
      .then(response => response.data.user);
  }

  public static requestReset(data: PasswordResetRequestDTO): Promise<AxiosResponse> {
    return Axios.put(`${BASE_URL}/reset`, data);
  }

  public static reset({ password, token }: PasswordResetDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/reset`, { password, token });
  }

  public static checkResetToken(prt: string): Promise<PasswordResetTokenDTO> {
    return Axios.get<PasswordResetTokenDTO>(`${BASE_URL}/reset`, {
      params: {
        prt,
      },
    })
      .then(response => response.data);
  }
}
