import React, { createContext, useContext, useEffect, useState } from 'react';
import { Ability, AnyAbility, PureAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { useAuth } from './AuthContext';

const AbilityContext = createContext<AnyAbility>(new PureAbility());
AbilityContext.displayName = `AbilityContext`;

export const AbilityProvider: React.FC = ({ children }) => {
  const [ abilities, setAbilities ] = useState<Ability>(new Ability());
  const { user } = useAuth();

  useEffect(() => {
    setAbilities(new Ability(user?.permissions));
  }, [ user?.permissions ]);

  return (
    <AbilityContext.Provider value={abilities}>
      {children}
    </AbilityContext.Provider>
  );
};

export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbility = (): AnyAbility => {
  const context = useContext(AbilityContext);
  if (context === undefined) {
    throw new Error(`useAbility must be used within a AbilityProvider`);
  }
  return context;
};
